<template>
    <div v-if="!isEdit" flex="cross:center">
        <!-- <el-button size="mini" type="primary" plain @click="savePdf">保存文件</el-button> -->
        <el-button v-if="isPad" size="mini" type="primary" plain @click="padDownloadPdf">{{ pdfDownload ?
            '文档下载' : '生成文件预览' }}</el-button>
        <!-- <el-button v-else v-print="printObj" size="mini" type="primary" plain >生成文件预览</el-button> -->
        <el-button v-else @click="pcDownloadPdf" size="mini" type="primary" plain>生成文件预览</el-button>
    </div>
</template>

<script>
import { downloadPdf } from '@/api/module'
export default {
    data() {
        return {
            printObj: {
                id: 'view_warper',
                popTitle: '', // 打印配置页上方的标题
                extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
                preview: false, // 是否启动预览模式，默认是false
                previewTitle: '预览的标题', // 打印预览的标题
                previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
                zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
                standard: '',
                extarCss: '',
                beforeOpenCallback() {
                    console.log('开始打印之前！');
                },
                previewOpenCallback() {
                    // console.log('关闭了打印工具');
                }
            },
            isEdit: false
        }
    },
    props: {
        pdfDownload: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        keyId: {
            type: [String, Number],
            default: ''
        },
        moduleName: {
            type: String,
            default: ''
        },
        formData: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        isPad() {
            return window.innerWidth <= 1200;
        }
    },
    methods: {
        savePdf() {
            // return
            const targetDom = document.getElementById('view_warper')
            const title = this.title + this.parseTime(new Date().getTime(), "{y}年{m}月{d}日{h}时{i}分");
            this.getPdf(targetDom, title, false).then(base64 => {
                // console.log(res);
                this.uploadPdf(title + ".pdf", base64);
            })
        },
        /*
          默认 pdfDownload = false ，如果是 pad端，就通过 postMessage 调用 uni-app 的方法来打开pad浏览器新页面
          之后，浏览器打开的页面仍然是此页面，此时 pdfDownload = true,再次点击按钮则为下载pdf。之后需要手动打开汉小印app去打印了
        */
        async padDownloadPdf() {
            if (this.isPad) {
                if (!this.pdfDownload) {
                    const replacedString = this.$route.fullPath.replace('pdfDownload=false', 'pdfDownload=true');
                    uni.postMessage({ data: { action: "base64", url: replacedString } });
                } else {
                    this.isEdit = true
                    // let title = this.title + this.parseTime(new Date().getTime(), "{y}年{m}月{d}日{h}时{i}分");
                    // this.getPdf(document.getElementById('view_warper'), title, true);
                    await this.pcDownloadPdf()
                    this.isEdit = false
                }
            }
        },
        async pcDownloadPdf() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // this.$emit('saveData', async (id) => {
            let res = await downloadPdf(this.formData, { moduleName: this.moduleName })
            var a = document.createElement('a');
            a.download = 'download'
            a.href = res.data.pdfPath;
            a.target = "_blank"
            a.click()
            a.remove()
            loading.close();
            // })

        },
    }
}
</script>
<style scoped></style>