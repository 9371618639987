import request from '@/utils/requestApi'

// 查询案卷详细
export function get(archivesId) {
    return request({
        url: `/archives/archives/${archivesId}`,
        method: 'GET'
    })
}

// 查询案卷详细
export function getArchiveCommon(archivesId) {
    return request({
        url: `/archives/archives/getCommon/${archivesId}`,
        method: 'GET'
    })
}


// 查询案卷相关用户列表
export function getDoPersonList(archivesId) {
    return request({
        url: `/archives/archives/getDoPersonList/${archivesId}`,
        method: 'GET'
    })
}