import { setToken, getToken } from '@/utils/auth'
import { get as GetData, add as AddArchiveModule, edit as EditArchivesModule, getDetailByNoticeId } from '@/api/module'
import { getUser as GetUser } from '@/api/system/user'
import { getArchiveCommon as GetArchiveCommon } from '@/api/archives/index'
import { MODULE_DICTID, MODULE_DICTID_NAMES } from '@/const/modules/index.js'
import { parseTime, checkType } from '@/utils'
export default {
  data() {
    return {
      userInfo: {},
      currentRow: {},
      formData: {},
      keyId: null,
      params: {},
      loading: false,
      pdfDownload: false, // 控制底部按钮
      isEdit: false, // 控制页面当前是否预览状态
      printObj: {
        id: 'view_warper',
        popTitle: '', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '预览的标题', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
        standard: '',
        extarCss: '',
      },
      archivesData: {}
    };
  },
  computed: {
    headers() {
      return {
        Authorization: 'Bearer ' + getToken()
      }
    },
    currentModule() {
      const currentDictId = this.$route.query.dictId;
      const currentModule = Object.entries(MODULE_DICTID).find(([key, value]) => value == currentDictId);
      const [moduleName, moduleId] = currentModule;
      return { moduleName, moduleId }
    },
    defaultPersonName() {
      return this.userInfo && this.userInfo.nickName || this.currentRow.archives && this.currentRow.archives.doPerson
    },
    pageName() {
      const dictId = parseInt(this.$route.query.dictId)
      return MODULE_DICTID_NAMES[dictId]
    }
  },
  watch: {
    '$route.query': {
      async handler(newVal) {
        this.params = newVal
        const { token, dictId, keyId, noticeId, pdfDownload, isEdit, archivesId } = newVal
        this.pdfDownload = pdfDownload == 'true'
        this.isEdit = isEdit == 'true'
        setToken(token)
        if (this.beforeHandleData) {
          await this.beforeHandleData(newVal)
        } else {
          if (!dictId) return alert('请选择对应模块')
        }
        this.currentRow = (await getDetailByNoticeId(noticeId) || {}).data
        GetUser(this.$route.query.userId).then(res => this.userInfo = res.data)
        if (this.currentRow.archivesId) {
          this.archivesData = (await GetArchiveCommon(archivesId || this.currentRow.archivesId) || {}).data
        }
        this.keyId = parseInt(keyId)

        if (keyId === '0') {
          this.formData = Object.assign({}, this.initForm())
        } else {
          let res = await GetData(keyId, { moduleName: this.currentModule.moduleName })
          this.formData = res.data
        }
        if (this.afterHandleData) {
          this.afterHandleData(this.formData)
        }
      },
      immediate: true
    },
  },
  methods: {
    async submitFormBackBefore(beofreDone) {
      this.loading = true
      if (this.submitFormBefore) {
        this.submitFormBefore()
      }
      if (this.formData.createTime) {
        delete this.formData.createTime
      }
      let times = []
      for (let key in this.formData) {
        if (key.includes('Time')) {
          times.push(key)
        }
      }
      times.forEach(item => {
        this.formData[item] = parseTime(this.formData[item])
      })
      if (this.formData.fireAddress && checkType(this.formData.fireAddress) == 'array') {
        this.formData.fireAddress = this.formData.fireAddress.join(',')
      }
      const { moduleName, moduleId } = this.currentModule
      let res = null;
      try {
        if (!this.keyId) {
          res = await AddArchiveModule({
            ...this.formData,
            noticeId: this.params.noticeId,
            dictId: this.params.dictId,
          }, { moduleName: this.currentModule.moduleName })
          this.keyId = res.data
        } else {
          await EditArchivesModule({
            ...this.formData,
            noticeId: this.params.noticeId,
            dictId: this.params.dictId,
            [moduleName + 'Id']: this.keyId
          }, { moduleName: this.currentModule.moduleName })
        }
        this.loading = false
        beofreDone(this.keyId)
      } catch {
        this.loading = false
      }

    },
    async submitForm() {
      try {
        if (this.$refs.form) {
          this.$refs.form.validate(async (valid, done, msg) => {
            if (valid) {
              await this.submitFormBackBefore(() => {
                this.backUniApp("success");
                window.parent.postMessage("success", "*");
              })
              done()
            } else {
              console.log(msg);
              return false
            }
          })
        } else {
          await this.submitFormBackBefore(() => {
            this.backUniApp("success");
            window.parent.postMessage("success", "*");
          })
        }
      } catch (error) {
        this.backUniApp("error");
        window.parent.postMessage("error", "*");
      }

    },
    backUniApp(type) {
      uni.postMessage({ data: { action: type } })
    },
    handleCancel() {
      this.formData = Object.assign({}, this.initForm())
      this.backUniApp("cancel");
      window.parent.postMessage("cancel", "*");
    },
  },
};