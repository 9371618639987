<template>
    <div v-if="!pdfDownload" class="footer">
        <el-button type="primary" :disabled="submitDis" @click="submitForm">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
    </div>
</template>

<script>
export default {
    props: {
        pdfDownload: {
            type: Boolean,
            default: false
        },
        submitDis: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        submitForm() {
            this.$emit('submit')
        }
    }
}
</script>
<style scoped>
.footer {
    display: flex;
    justify-content: flex-end;
}
</style>