import request from '@/utils/requestApi'

// 查询案卷详细
export function get(id, option) {
    return request({
        url: `/V2/archives/${option.moduleName}/${id}`,
        method: 'GET'
    })
}

export function add(data, option) {
    return request({
        url: `/V2/archives/${option.moduleName}`,
        method: 'POST',
        data
    })
}

export function edit(data, option) {
    return request({
        url: `/V2/archives/${option.moduleName}`,
        method: 'PUT',
        data
    })
}


export function downloadPdfOld(id, option) {
    return request({
        url: `/V2/archives/${option.moduleName}/downloadPDF/${id}`,
        method: 'GET'
    })
}

export function downloadPdf(data = {}, option = {}) {
    return request({
        url: `/V2/archives/${option.moduleName}/downloadPDF`,
        method: 'POST',
        data
    })
}



export function getDetailByNoticeId(noticeId) {
    return request({
        url: `/archives/notice/${noticeId}`,
        method: 'GET'
    })
}

