<template>
    <el-dialog title="签名" class="avue-dialog my-avue-dialog" :visible.sync="signatureVisible" width="550px" append-to-body
        :before-close="handleClose">
        <div>
            <avue-sign ref="signatureRef" />
            <div flex="main:right">
                <el-button @click="clearSignature">清空</el-button>
                <el-button type="primary" @click="generateSignature">生成</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    model: {
        prop: 'signatureVisible',
        event: 'input'
    },
    props: {
        signatureVisible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        generateSignature() {
            const signUrl = this.$refs.signatureRef.submit(80, 32)
            this.$emit('submit', signUrl)
            this.clearSignature()
            this.$emit('input', false)
        },
        clearSignature() {
            if (this.$refs.signatureRef) {
                this.$refs.signatureRef.clear()
            }
        },
        handleClose() {
            this.clearSignature()
            this.$emit('input', false)
        }
    },
    beforeDestroy() {
        this.clearSignature()
    }
}
</script>

<style scoped></style>
