export const MODULE_DICTID = {
	note: 2, // 询问笔录
	extract: 3, // 物品提取清单
	inquest: 4, // 勘验笔录
	specialty: 5, // 现场专业图
	sketch: 6, // 现场草图
	loss: 10, // 损失清单
	evidence: 11, // 证据材料
	photo: 12, // 火灾现场照片
	video: 13, // 视频管理
	accident: 14, // 事故认定书
}

export const MODULE_ID = {
	[MODULE_DICTID.note]: "noteId",
	[MODULE_DICTID.extract]: "extractId",
	[MODULE_DICTID.inquest]: "inquestId",
	[MODULE_DICTID.specialty]: "specialtyId",
	[MODULE_DICTID.sketch]: "sketchId",
	[MODULE_DICTID.loss]: "lossId",
	[MODULE_DICTID.evidence]: "evidenceId",
	[MODULE_DICTID.photo]: "sceneId",
	[MODULE_DICTID.video]: "videoId",
	[MODULE_DICTID.accident]: "accidentId",
}


export const MODULE_DICTID_NAMES = {
	[MODULE_DICTID.note]: "询问笔录",
	[MODULE_DICTID.extract]: "物品提取清单",
	[MODULE_DICTID.inquest]: "勘验笔录",
	[MODULE_DICTID.specialty]: "现场专业图",
	[MODULE_DICTID.sketch]: "现场草图",
	[MODULE_DICTID.loss]: "损失清单",
	[MODULE_DICTID.evidence]: "证据材料",
	[MODULE_DICTID.photo]: "火灾现场照片",
	[MODULE_DICTID.video]: "视频管理",
	[MODULE_DICTID.accident]: "事故认定书",
};



